import React from "react";
import { graphql } from "gatsby";
import Layout from "../layouts";
import SEO from "../components/seo";
import PropTypes from "prop-types";
import PageBuilder from "../modules/page-builder";
import BlockContent from "@sanity/block-content-to-react";
import serializers from "../helpers/text-serializer";
import Header from "../modules/header";

export const query = graphql`
  query PolicyQuery($id: String!) {
    page: sanityPagePolicy(id: { eq: $id }) {
      title
      image {
        asset {
          _id
          altText
          metadata {
            lqip
            dimensions {
              width
              height
              aspectRatio
            }
          }
        }
        hotspot {
          height
          width
          x
          y
        }
      }
      heroTitle
      heroText
      breadcrumbs {
        title
        slug
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
      ...policyBuilderFields
    }
  }
`;

const Policy = props => {
  const { data } = props;
  const page = data.page;

  return (
    <Layout className="alumni">
      <SEO
        title={page.title}
        excerpt={page.excerpt}
        image={page.image}
        seo={page.seo}
        taxonomy={page.taxonomy}
      />
      <Header
        title={page.heroTitle || page.title}
        breadcrumbs={page.breadcrumbs}
        image={page.image}
        text={page.heroText}
        backgroundColour={"green"}
      />
      {page._rawBody?.[0] && (
        <div className="container m-body-content-wrapper">
          {page._rawBody && (
            <BlockContent
              className="m-body-content"
              blocks={page._rawBody}
              serializers={{
                serializers,
                types: {
                  block: serializers.types.block,
                  undefined: serializers.undefined,
                  blockVideo: serializers.blockVideo,
                  blockFigure: serializers.blockFigure,
                  pdfButton: serializers.pdfButton,
                },
                marks: {
                  link: serializers.marks.link,
                },
              }}
              renderContainerOnSingleChild={true}
            />
          )}
        </div>
      )}
      <PageBuilder builder={page.pageBuilder} />
    </Layout>
  );
};

Policy.propTypes = {
  data: PropTypes.object,
};

export default Policy;
